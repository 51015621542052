<template>
    <div class="invoice-form">
        <div class="header">
            <h2>{{ isEditMode ? $t('filters.editTitle') : $t('filters.uploadTitle') }}</h2>
            <button v-if="isEditMode" type="button" class="back-button" @click="goBack">
                {{ $t('actions.back') }}
            </button>
        </div>
        <form class="form" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group">
                    <label for="invoice" class="invoice-label">
                        <span class="icon">📷</span>
                        {{ $t('filters.choose') }}
                    </label>
                    <input id="invoice" type="file" accept="image/*" class="invoice-input" @change="handleFileChange" />
                </div>
                <div class="form-group">
                    <label for="date" class="invoice-label">
                        <span class="icon">📅</span>
                        {{ $t('filters.date') }}
                    </label>
                    <input id="date" v-model="form.date" type="date" class="invoice-input" />
                </div>
                <div class="form-group">
                    <label for="amount" class="invoice-label">
                        <span class="icon">💰</span>
                        {{ $t('filters.amount') }}
                    </label>
                    <input id="amount" v-model="form.amount" type="number" class="invoice-input" />
                </div>
                <div class="form-group">
                    <label for="currency" class="invoice-label">
                        <span class="icon">💱</span>
                        {{ $t('filters.currency') }}
                    </label>
                    <select id="currency" v-model="form.amountCurrency" class="invoice-input">
                        <option value="GBP">GBP</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="PLN">PLN</option>
                    </select>
                </div>
                <div v-if="tags" class="form-group">
                    <div for="tags" class="invoice-label">
                        <span>🏷️</span>
                        {{ $t('filters.tags') }}
                    </div>
                    <TagMultiplyFilter
                        :availableTags="tags"
                        :selectedInvoiceTags="invoice.tags"
                        @update:selectedTags="handleTagFilterChange"
                    />
                </div>
                <div class="form-group">
                    <button type="submit" class="submit-button">
                        {{ isEditMode ? $t('actions.update') : $t('actions.upload') }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import InvoiceService from '../services/invoice.service';
    import TagMultiplyFilter from './TagMultiplyFilter.vue';

    export default {
        name: 'UploadForm',
        components: { TagMultiplyFilter },
        props: {
            invoice: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                form: {
                    invoiceFile: null,
                    date: this.invoice.date || '',
                    amount: this.invoice.amount || '',
                    amountCurrency: this.invoice.amountCurrency || 'GBP',
                    tagIDs: this.invoice.tagIDs || [],
                },
                service: new InvoiceService(),
                tags: null,
            };
        },
        computed: {
            isEditMode() {
                return !!this.invoice.id;
            },
        },
        watch: {
            invoice: {
                handler(newInvoice) {
                    this.form.date = newInvoice.date || '';
                    if (newInvoice.amount) {
                        this.form.amount = newInvoice.amount.cents || '';
                    }
                    this.form.amountCurrency = newInvoice.amountCurrency || 'GBP';
                },
                immediate: true,
            },
        },
        mounted() {
            window.addEventListener('storage', this.handleStorageChange);
        },
        beforeDestroy() {
            window.removeEventListener('storage', this.handleStorageChange);
        },
        async created() {
            const tagsList = await this.$store.dispatch('tag/getTags');
            this.tags = tagsList;
        },
        methods: {
            handleFileChange(event) {
                const file = event.target.files[0];
                if (file) {
                    this.form.invoiceFile = file;
                }
            },
            handleTagFilterChange(tags) {
                const tagIDs = tags.map(tag => tag.id);
                this.form.tagIDs = tagIDs;
            },
            async handleSubmit() {
                try {
                    const formData = new FormData();
                    if (this.form.invoiceFile) {
                        formData.append('invoice', this.form.invoiceFile);
                    }
                    formData.append('date', this.form.date);
                    formData.append('amount_cents', this.form.amount);
                    formData.append('amount_currency', this.form.amountCurrency);

                    this.form.tagIDs.forEach(tagID => {
                        formData.append('tag_ids[]', tagID);
                    });

                    const { data } = this.isEditMode
                        ? await this.service.update(formData, this.invoice.id)
                        : await this.service.save(formData);

                    this.$emit('formSubmitted');
                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.save.success.title'),
                        message: this.$t('notification.save.success.message'),
                    });
                    this.$router.push({ path: `/invoice/view/${data.data.id}` });
                } catch (error) {
                    console.error('Error submitting form:', error);
                }
            },
            goBack() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style scoped lang="scss">
    .invoice-form {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;

        .theme-dark & {
            background-color: #333;
            color: #ffa500;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    h2 {
        color: #333;
        text-align: center;
        margin: 0;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .back-button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
            }
        }
    }

    .form {
        width: 100%;
    }

    .form-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
    }

    .form-group {
        flex: 1;
        min-width: 150px;
        position: relative;
    }

    .invoice-label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        color: #555;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .icon {
        margin-right: 5px;
    }

    .invoice-input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: #007bff;
            box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
        }

        .theme-dark & {
            background-color: #555;
            color: #ffa500;
            border-color: #444;

            &:focus {
                border-color: #66afe9;
                box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
            }
        }
    }

    .submit-button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-top: 24px;
        min-width: 100px;

        &:hover {
            background-color: #0056b3;
        }

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
            }
        }
    }

    @media (max-width: 768px) {
        .form-row {
            flex-wrap: wrap;
        }
    }
</style>
