<template>
    <transition name="slide-up">
        <div v-if="visible" :class="['floating-footer', theme]">
            <div class="content">
                <span><strong></strong></span>
                <div class="buttons">
                    <at-button :disabled="disabledButtons" class="footer-btn" type="error" @click="deleteSelected">
                        <i class="icon icon-trash" />
                        {{ $t('control.delete') }}
                    </at-button>
                    <div class="divider" />
                    <at-button class="footer-btn" @click="$emit('close')">
                        {{ $t('control.cancel') }}
                    </at-button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'FloatingFooter',
        props: {
            visible: {
                type: Boolean,
                required: true,
            },
            disabledButtons: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                theme: localStorage.theme,
            };
        },
        methods: {
            async deleteSelected() {
                try {
                    this.$emit('delete');
                } catch (e) {
                    console.error(e);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .floating-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        border-top: 1px solid #ddd;
        padding: 15px;
        z-index: 999;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
        .theme-dark & {
            background-color: #333;
            color: #ffa500;
            border-top: 1px solid #1f1f1f;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .buttons {
        display: flex;
        align-items: center;
    }

    .footer-btn {
        margin-right: 10px;
        .theme-dark & {
            background-color: rgb(40, 40, 40);
            border-color: rgb(40, 40, 40);
            color: #ffa500;

            &:hover {
                background-color: #555;
            }
        }
    }

    .divider {
        background-color: #ddd;
        width: 1px;
        height: 20px;
        margin-right: 10px;
    }
</style>
