<template>
    <div class="invoice-list">
        <div class="filter-panel">
            <div class="filter-header">
                <h2>{{ $t('filters.title') }}</h2>
                <a class="toggle-view" @click="toggleDisplayMode">
                    {{ displayMode === 'card' ? '📋' : '📦' }}
                </a>
            </div>
            <div class="filters">
                <!-- Date Filter -->
                <div class="filter-group">
                    <label for="dateFilter">📅 {{ $t('filters.date') }}:</label>
                    <Calendar id="custom-calendar" :show-range="true" @change="handleDateFilterChange" />
                </div>

                <!-- Amount Filter -->
                <div class="filter-group">
                    <label for="amountFilter">💰 {{ $t('filters.amount') }}:</label>
                    <input
                        id="amountFilter"
                        v-model="filters.amount_cents"
                        class="invoice-input"
                        type="number"
                        @input="fetchFilteredInvoices"
                    />
                </div>

                <!-- Currency Filter -->
                <div class="filter-group">
                    <label for="currencyFilter">💱 {{ $t('filters.currency') }}:</label>
                    <select
                        id="currencyFilter"
                        v-model="filters.currency"
                        class="invoice-input"
                        @change="fetchFilteredInvoices"
                    >
                        <option value="">{{ $t('options.all') }}</option>
                        <option value="GBP">GBP</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="PLN">PLN</option>
                    </select>
                </div>

                <!-- Tag Filter -->
                <div class="filter-group">
                    <label for="tagFilter">🏷️ {{ $t('filters.tags') }}:</label>
                    <TagMultiplyFilter :availableTags="availableTags" @update:selectedTags="handleTagFilterChange" />
                </div>

                <!-- Sort Group -->
                <div class="sort-group">
                    <label for="sortOrder">
                        🔃 {{ $t('filters.sortBy') }}:
                        <a @click="toggleSortDirection">
                            {{ sortDirection === 'asc' ? `⬆️ ${$t('filters.asc')}` : `⬇️ ${$t('filters.desc')}` }}
                        </a>
                    </label>
                    <select id="sortOrder" v-model="sortOrder" class="invoice-input" @change="fetchFilteredInvoices">
                        <option value="date">{{ $t('filters.date') }}</option>
                        <option value="amount_cents">{{ $t('filters.amount') }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div v-if="displayMode === 'card'" class="invoice-cards">
            <div
                v-for="invoice in invoices"
                :key="invoice.id"
                class="invoice-card"
                :class="{ selected: selectedInvoices.includes(invoice.id) }"
                @click="toggleSelectInvoice(invoice.id)"
            >
                <div class="invoice-details">
                    <div class="invoice-date">
                        <strong>📅 {{ $t('filters.date') }}:</strong> {{ invoice.attributes.date }}
                    </div>
                    <div class="invoice-amount">
                        <strong>💰 {{ $t('filters.amount') }}:</strong> {{ invoice.attributes.amount_format }}
                    </div>
                    <div class="invoice-currency">
                        <strong>💱 {{ $t('filters.currency') }}:</strong> {{ invoice.attributes.amount_currency }}
                    </div>
                    <div class="invoice-tags">
                        <strong>🏷️ {{ $t('filters.tags') }}:</strong> {{ invoiceTagsName(invoice) }}
                    </div>
                </div>
                <div class="invoice-actions">
                    <button @click.stop="showInvoice(invoice)">{{ $t('actions.show') }}</button>
                    <button @click.stop="editInvoice(invoice)">{{ $t('actions.edit') }}</button>
                    <tag-selector
                        :initialSelectedTags="invoice.attributes.tags"
                        :invoiceId="invoice.id"
                        :availableTags="availableTags"
                        @update-tags="updateInvoiceTags"
                    />
                </div>
            </div>
        </div>

        <table v-else class="invoice-table">
            <thead>
                <tr>
                    <th><input type="checkbox" @change="toggleSelectAll" /></th>
                    <th>📅 {{ $t('filters.date') }}</th>
                    <th>💰 {{ $t('filters.amount') }}</th>
                    <th>💱 {{ $t('filters.currency') }}</th>
                    <th>🏷️ {{ $t('filters.tags') }}</th>
                    <th>{{ $t('filters.actions') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="invoice in invoices" :key="invoice.id">
                    <td><input v-model="selectedInvoices" type="checkbox" :value="invoice.id" /></td>
                    <td>{{ invoice.attributes.date }}</td>
                    <td>{{ invoice.attributes.amount_format }}</td>
                    <td>{{ invoice.attributes.amount_currency }}</td>
                    <td>{{ invoiceTagsName(invoice) }}</td>
                    <td>
                        <button class="mr-4" @click="showInvoice(invoice)">{{ $t('actions.show') }}</button>
                        <button class="mr-4" @click="editInvoice(invoice)">{{ $t('actions.edit') }}</button>
                        <tag-selector
                            :initialSelectedTags="invoice.attributes.tags"
                            :invoiceId="invoice.id"
                            :availableTags="availableTags"
                            @update-tags="updateInvoiceTags"
                        />
                    </td>
                </tr>
            </tbody>
        </table>

        <FloatingFooter
            :visible="selectedInvoices.length > 0"
            :intervals="selectedInvoices.map(id => invoices.find(invoice => invoice.id === id))"
            :disabledButtons="disabledButtons"
            @delete="deleteSelected"
            @close="clearSelection"
        />
    </div>
</template>

<script>
    import InvoiceService from '../services/invoice.service';
    import TagService from '@/modules/tags/services/tag.service';
    import FloatingFooter from './FloatingFooter.vue';
    import TagSelector from './TagSelector.vue';
    import Calendar from '@/components/Calendar.vue';
    import { debounce } from 'lodash';
    import TagMultiplyFilter from './TagMultiplyFilter';

    export default {
        components: {
            FloatingFooter,
            TagSelector,
            TagMultiplyFilter,
            Calendar,
        },
        data() {
            return {
                invoices: [],
                filters: {
                    startDate: new Date(),
                    endDate: new Date(),
                    amount_cents: '',
                    currency: '',
                    tags: [],
                },
                sortOrder: 'date',
                sortDirection: 'asc',
                service: new InvoiceService(),
                tagService: new TagService(),
                availableTags: [],
                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 10,
                queryParams: {
                    page: +(this.$route.query.page || 1),
                },
                displayMode: '',
                loadMore: true,
                selectedInvoices: [],
                disabledButtons: false,
                showTagModal: false,
                currentInvoiceTags: [],
                currentInvoiceId: null,
            };
        },
        created() {
            this.chooseDisplayMode();
            this.fetchFilteredInvoices();
            this.fetchTags();
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            chooseDisplayMode() {
                this.displayMode = localStorage.displayMode || 'table';
            },
            invoiceTagsName(invoice) {
                if (invoice.attributes.tags.length != 0) {
                    const names = invoice.attributes.tags.map(tag => tag.name);
                    return names.join(', ');
                }
                return '';
            },
            async fetchTags() {
                try {
                    const { data } = await this.tagService.getWithFilters({ disable_pagy: true });
                    this.availableTags = data.data;
                } catch (error) {
                    console.error('Error fetching invoices:', error);
                }
            },
            fetchFilteredInvoices: debounce(async function () {
                try {
                    const res = await this.service.getWithFilters({
                        ...this.filters,
                        startDate: this.filters.startDate,
                        endDate: this.filters.endDate,
                        sortOrder: this.sortOrder,
                        sortDirection: this.sortDirection,
                    });
                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;
                    this.invoices = data;
                } catch (error) {
                    console.error('Error fetching filtered invoices:', error);
                }
            }, 500),
            showInvoice(invoice) {
                this.$router.push({ path: `view/${invoice.id}` });
            },
            editInvoice(invoice) {
                this.$router.push({ path: `edit/${invoice.id}` });
            },
            toggleSortDirection() {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                this.fetchFilteredInvoices();
            },
            toggleDisplayMode() {
                this.displayMode = this.displayMode === 'card' ? 'table' : 'card';
                localStorage.displayMode = this.displayMode;
            },
            async onPageChange(page) {
                await this.handlePageChange(page);
            },
            handleDateFilterChange(params) {
                this.filters.startDate = params.start;
                this.filters.endDate = params.end;
                this.fetchFilteredInvoices();
            },
            handlePageChange(page) {
                if (this.queryParams.page === page) {
                    return;
                }
                this.queryParams.page = page;
                return this.fetchFilteredInvoices();
            },
            async handleScroll() {
                const bottomOfWindow = window.scrollY + window.innerHeight >= document.documentElement.scrollHeight;
                if (bottomOfWindow) {
                    this.page += 1;
                    await this.loadMoreInvoices();
                }
            },
            async loadMoreInvoices() {
                try {
                    if (this.loadMore) {
                        const res = await this.service.getWithFilters({
                            ...this.filters,
                            page: this.page,
                            sortOrder: this.sortOrder,
                            sortDirection: this.sortDirection,
                        });
                        if (res.status === 204) {
                            this.loadMore = false;
                            return;
                        }
                        const { data } = res.data;
                        this.invoices.push(...data);
                    }
                } catch (error) {
                    console.error('Error loading more invoices:', error);
                }
            },
            toggleSelectInvoice(invoiceId) {
                const index = this.selectedInvoices.indexOf(invoiceId);
                if (index >= 0) {
                    this.selectedInvoices.splice(index, 1);
                } else {
                    this.selectedInvoices.push(invoiceId);
                }
            },
            toggleSelectAll(event) {
                if (event.target.checked) {
                    this.selectedInvoices = this.invoices.map(invoice => invoice.id);
                } else {
                    this.selectedInvoices = [];
                }
            },
            async updateInvoiceTags(invoiceId, tags) {
                try {
                    await this.tagService.addTags({
                        object_id: invoiceId,
                        object_model_name: 'invoice',
                        tag_ids: tags,
                    });
                    this.fetchInvoices();
                } catch (error) {
                    console.error('Error updating tags:', error);
                }
            },
            async deleteSelected() {
                try {
                    await this.service.bulkDestroy({
                        invoice_ids: this.selectedInvoices,
                    });
                } catch (error) {
                    console.error('Error destroy tags:', error);
                }
                this.selectedInvoices = [];
                this.fetchInvoices();
            },
            clearSelection() {
                this.selectedInvoices = [];
                this.disabledButtons = false;
            },
            handleTagFilterChange(params) {
                this.filters.tags = params;
                this.fetchFilteredInvoices();
            },
        },
    };
</script>

<style scoped lang="scss">
    h2 {
        color: #333;
        text-align: center;
        margin-bottom: 20px;

        .theme-dark & {
            color: #ffa500;
        }
    }
    th {
        .theme-dark & {
            background-color: #333 !important;
        }
    }
    td,
    th {
        border: 1px solid #555 !important;
    }
    .invoice-list {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;

        .theme-dark & {
            background-color: #333;
            color: #ffa500;
        }
    }

    .filter-panel {
        margin-bottom: 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #444;
            color: #ffa500;
        }
    }

    .filter-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .toggle-view {
        cursor: pointer;
        color: #007bff;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .toggle-view:hover {
        color: #0056b3;

        .theme-dark &:hover {
            color: #ffd700;
        }
    }

    .filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        margin-bottom: 10px;
        .theme-dark & {
            color: #ffa500;
        }
    }

    .filter-group,
    .sort-group {
        flex: 1;
        min-width: 150px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .filter-group label,
    .sort-group label {
        font-weight: bold;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .filter-group input,
    .filter-group select,
    .sort-group select {
        width: 100%;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        min-width: 150px;
        flex-grow: 1;
    }

    .sort-group button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        .theme-dark & {
            background-color: #444;
            color: #ffa500;
        }
    }

    .sort-group button:hover {
        background-color: #0056b3;

        .theme-dark &:hover {
            background-color: #555;
        }
    }
    .invoice-input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: #007bff;
            box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
        }

        .theme-dark & {
            background-color: #555;
            color: #ffa500;
            border-color: #444;

            &:focus {
                border-color: #66afe9;
                box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
            }
        }
    }

    .invoice-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .invoice-card {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        flex: 1 1 calc(33.333% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        transition: background-color 0.3s;
        position: relative;

        .theme-dark & {
            background-color: #444;
            color: #ffa500;
        }
    }

    .invoice-card.selected {
        background-color: #e0f7fa;

        .theme-dark &.selected {
            background-color: #555;
        }
    }

    .invoice-details {
        margin-bottom: 20px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .invoice-details div {
        margin-bottom: 10px;
    }

    .invoice-actions {
        display: flex;
        gap: 10px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .invoice-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;

        .theme-dark & {
            background-color: #444;
            color: #ffa500;
        }
    }

    .invoice-table th,
    .invoice-table td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: left;
    }

    th {
        .theme-dark & {
            color: #ffa500;
            background-color: #444;
        }
    }

    .invoice-table th {
        background-color: #f1f1f1;

        .theme-dark & th {
            background-color: #555;
        }
    }

    .mr-4 {
        margin-right: 4px;
    }

    button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
            }
        }
    }

    button:hover {
        background-color: #0056b3;

        .theme-dark & :hover {
            background-color: #555;
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.9);
        color: #333;
        text-align: center;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: rgba(51, 51, 51, 0.9);
            color: #ffa500;
        }
    }

    .delete-btn {
        background-color: #dc3545;
        width: 55px;
        color: #fff;
        border: none;
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        .theme-dark & {
            background-color: #922529;
        }
    }

    .delete-btn:hover {
        background-color: #c82333;

        .theme-dark &:hover {
            background-color: #a71d2a;
        }
    }

    ::v-deep .input {
        width: 100% !important;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;

        .theme-dark & {
            background-color: #444;
            color: #ffa500;
            border-color: #666;
        }
    }

    @media (max-width: 768px) {
        .filters {
            flex-direction: column;
            gap: 15px;
        }

        .invoice-card {
            flex: 1 1 100%;
        }
    }
</style>
