<template>
    <div class="invoice-show">
        <div class="invoice-header">
            <div class="invoice-header-top">
                <h1>Invoice Details</h1>
                <button class="back-button" @click="goBack">Back</button>
            </div>
            <div class="invoice-details">
                <p><strong>📅 Date:</strong> {{ invoice.date }}</p>
                <p><strong>💲 Amount:</strong> {{ invoice.amount_format }}</p>
                <p><strong>💱 Currency:</strong> {{ invoice.amount_currency }}</p>
                <div class="tag-data">
                    <div class="tag-label-title">
                        <p><strong>🏷️ Tags:</strong></p>
                    </div>
                    <div
                        v-for="tag in invoice.tags"
                        :key="tag.id"
                        class="tag"
                        :style="{ backgroundColor: tag.color }"
                        :title="tag.name"
                    ></div>
                </div>
            </div>
        </div>
        <div class="invoice-body">
            <img :src="invoiceUrl" class="invoice-image" alt="Invoice Image" @click="showImageModal" />
        </div>
        <div v-if="isModalVisible" class="modal" @click="hideImageModal">
            <div class="modal-content" @click.stop>
                <span class="close-button" @click="hideImageModal">&times;</span>
                <div class="image-container">
                    <img
                        ref="modalImage"
                        :src="invoiceUrl"
                        class="modal-image"
                        alt="Invoice Image"
                        @mousedown="startDrag"
                        @mousemove="onDrag"
                        @mouseup="endDrag"
                        @mouseleave="endDrag"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InvoiceService from '@/modules/invoice/services/invoice.service';

    export default {
        data() {
            return {
                invoice: {},
                service: new InvoiceService(),
                isModalVisible: false,
                isDragging: false,
                dragStartX: 0,
                dragStartY: 0,
                imgTranslateX: 0,
                imgTranslateY: 0,
            };
        },
        computed: {
            invoiceUrl() {
                if (this.invoice.invoice_url) {
                    return `${process.env.VUE_APP_API_URL}/${this.invoice.invoice_url}`;
                }
                return '';
            },
        },
        created() {
            this.fetchInvoice();
        },
        methods: {
            async fetchInvoice() {
                try {
                    const { data } = await this.service.getById(this.$route.params.id);
                    this.invoice = data.data.attributes;
                } catch (error) {
                    console.error('Error fetching invoice:', error);
                }
            },
            goBack() {
                this.$router.go(-1);
            },
            showImageModal() {
                this.isModalVisible = true;
            },
            hideImageModal() {
                this.isModalVisible = false;
                this.resetImagePosition();
            },
            startDrag(event) {
                this.isDragging = true;
                this.dragStartX = event.clientX;
                this.dragStartY = event.clientY;
            },
            onDrag(event) {
                if (!this.isDragging) return;
                const dx = event.clientX - this.dragStartX;
                const dy = event.clientY - this.dragStartY;
                this.imgTranslateX += dx;
                this.imgTranslateY += dy;
                this.dragStartX = event.clientX;
                this.dragStartY = event.clientY;
                this.updateImageTransform();
            },
            endDrag() {
                this.isDragging = false;
            },
            updateImageTransform() {
                this.$refs.modalImage.style.transform = `scale(1.5) translate(${this.imgTranslateX}px, ${this.imgTranslateY}px)`;
            },
            resetImagePosition() {
                this.imgTranslateX = 0;
                this.imgTranslateY = 0;
                this.$refs.modalImage.style.transform = '';
            },
        },
    };
</script>

<style scoped lang="scss">
    .invoice-show {
        margin: 20px;
        font-family: Arial, sans-serif;

        .invoice-header {
            background-color: #f5f5f5;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;

            .theme-dark & {
                background-color: #333;
                color: #ffa500;
            }

            .invoice-header-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                h1 {
                    font-size: 24px;
                    margin: 0;
                    .theme-dark & {
                        color: #ffa500;
                    }
                }

                .back-button {
                    background-color: #007bff;
                    color: white;
                    margin-top: 25px;
                    border: none;
                    padding: 10px 20px;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 16px;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #0056b3;
                    }
                    .theme-dark & {
                        color: #ffa500;
                        background-color: rgb(40, 40, 40);

                        &:hover {
                            background-color: #555;
                        }
                    }
                }
            }

            .invoice-details {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;

                p {
                    margin: 0;
                    font-size: 16px;

                    strong {
                        color: #333;
                    }
                }
                .theme-dark & {
                    p {
                        color: #ffa500;
                    }
                    strong {
                        color: #ffa500;
                    }
                }
            }
        }

        .invoice-body {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;

            .invoice-image {
                max-width: 100%;
                max-height: 80vh;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                transition: transform 0.3s;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        .modal {
            position: fixed;
            z-index: 1000;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;

            .modal-content {
                position: relative;
                padding: 20px;
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                display: flex;
                justify-content: center;
                align-items: center;

                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 25px;
                    color: #fff;
                    font-size: 35px;
                    font-weight: bold;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover,
                    &:focus {
                        color: #bbb;
                    }
                }

                .image-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;

                    .modal-image {
                        max-width: 100%;
                        max-height: 80vh;
                        cursor: move;
                        border-radius: 8px;
                        transition: transform 0.3s;
                    }
                }
            }
            .theme-dark & {
                .modal-content {
                    background-color: #333;
                    color: #ffa500;
                }
                .close-button {
                    color: #ffa500;
                }
            }
        }

        .tag {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 5px;
            margin-top: 5px;
            position: relative;
            cursor: pointer;

            &:hover::after {
                content: attr(title);
                position: absolute;
                top: 25px;
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;
                background-color: rgba(0, 0, 0, 0.8);
                color: #fff;
                padding: 5px 10px;
                border-radius: 5px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                font-size: 12px;
                z-index: 1;
            }

            .theme-dark & {
                &:hover::after {
                    background-color: #ffa500;
                    color: #333;
                }
            }
        }

        .tag-data {
            display: inline-flex;
            text-align: center;
            align-items: center;
        }

        .tag-label-title {
            margin-right: 7px;
        }
    }
</style>
