<template>
    <div class="invoice">
        <UploadForm></UploadForm>
        <InvoiceList></InvoiceList>
    </div>
</template>

<script>
    import UploadForm from '../../components/UploadForm';
    import InvoiceList from '../../components/InvoiceList';

    export default {
        name: 'Invoice',
        components: {
            UploadForm,
            InvoiceList,
        },
    };
</script>
